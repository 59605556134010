<template>  
    <div class="footer-head">
        <div class="footer-head-light-wrapper">
            <div class="footer-head-light-container">
                <svg class="footer-head-light_icon" width="48" height="117" viewBox="0 0 48 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25 66.6798L25 -9.99205e-07L25.6095 -9.7256e-07L25.6095 66.375L47.8591 88.319L47.8591 116.663L46.9448 116.663L46.9448 88.6238L25 66.6798Z" fill="white"/>
                    <circle cx="25" cy="4" r="4" fill="white"/>
                    <path d="M5.05633e-06 108.5L9.04608e-06 60.86L0.304798 60.86L24.0783 36.7826L24.0783 6L24.9927 6L24.9927 37.0873L24.9927 37.3921L0.914379 61.4696L0.914375 108.5L5.05633e-06 108.5Z" fill="white"/>
                </svg>
            </div>
        </div>
        <div class="footer-head__top text-c" :style="borderType === 'straight' && `background: #0F1A38`">
            <v-container fill-height :class="borderType === 'straight' ? 'exchange-footer' : '' ">
                <v-layout justify-center align-center>
                    <v-flex text-center>
                        <svg-icon class="amazing-lines-1" name="amazing-lines-1"/>
                        <svg-icon class="amazing-lines-2" name="amazing-lines-2"/>
                        <div :class="`footer-head__intro`" v-html="$t(paragraph)"></div>
                        <div :class="`footer-head__action`">
                            <buttonCallPopup classList="btn-main" type="btnCalc-2"></buttonCallPopup>
                        </div>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
    </div>
</template>      

<script>
export default {
    props: ['paragraph', 'borderType']
}
</script>

<style lang="sass">
@import '@/pages/asections/sectionFooter.sass'
</style>